import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import PageLoader from "./components/PageLoader";
import Home from "./containers/Home";
import TermsConditions from "./containers/Home/terms-condition";
import PrivacyPolicy from "./containers/Home/privacy-policy";
// import ComingSoon from "./containers/ComingSoon";
// const NotFound = lazy(() => import("./components/NotFound"));

export const AppRouter = () => (
    <Suspense fallback={<PageLoader text="Please wait while we load your application" />}>
        <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
            <Route path="/terms-conditions" exact element={<TermsConditions />} />
            <Route path="*" element={<Home />} />
        </Routes>
    </Suspense>
);
